import React from 'react'

const Loading = () => {
  return (
    <div>
        <h1 className="loading">
            Winter is coming ...
        </h1>
    </div>
  )
}

export default Loading